<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('offers_page.sent_offers')"
      :sub-title="$t('offers_page.sent_offers_subtitle')"
    />
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div
        v-if="getAllOffers && countOffersByStatusSent(2) < 1"
        class="d-flex flex-wrap flex-1 px-2"
      >
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
      </div>
      <div
        v-for="(offer, i) in listOffers"
        v-if="getAllOffers && getAllOffers.length > 0"
        :key="i"
        class="d-flex flex-wrap flex-1 px-2"
      >
        <b-col
          v-if="item.status !== 2"
          v-for="(item, index) in offer"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <offers-card
            :id="item.id"
            :title="item.name"
            :sub-title="item.created_at"
            :date="item.created_at"
            :employee-job-title="item.employee_job_title"
            :price="item.price"
            :check-box="true"
            :offer-sent="true"
            :check-box-selection="offersSelection.includes(item.id)"
          />
        </b-col>
      </div>
    </section>
    <page-header
      :title="$t('offers_page.other_offers')"
      :sub-title="$t('offers_page.other_offers_subtitle')"
    />
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div
        v-if="getAllOffers && countOffersByStatus(2) < 1"
        class="d-flex flex-wrap flex-1 px-2"
      >
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
      </div>
      <div
        v-for="(offer, i) in listOffers"
        v-if="getAllOffers && getAllOffers.length > 0"
        :key="i"
        class="d-flex flex-wrap flex-1 px-2"
      >
        <b-col
          v-for="(item, index) in offer"
          v-if="item.status === 2"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <offers-card
            :id="item.id"
            :title="item.name"
            :sub-title="item.created_at"
            :date="item.created_at"
            :employee-job-title="item.employee_job_title"
            :price="item.price"
            :check-box-selection="offersSelection.includes(item.id)"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import offersCard from '@/components/pages/orders/OffersCard.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // WTables,
    offersCard,
    WarningModal,
  },
  data() {
    return {
      filterData: {
        status: '',
        projects: '',
        page: 'all',
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      listOffers: [],
      offersSelection: [],
      showSendOffers: false,
    };
  },
  computed: {
    ...mapGetters({
      getAllOffers: 'getAllOffers',
      getTotalOffers: 'getTotalOffers',
    }),
  },
  watch: {
    getAllOffers(data) {
      const grouped = this._.groupBy(data, 'hr_job_title');
      this.listOffers = grouped;
    },
  },
  created() {
    this.filterData.projects = this.$route.params.id;
    this.loadData();
  },
  methods: {
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    ...mapActions({
      loadOffers: 'loadOffers',
      sendOffersSelection: 'sendOffersSelection',
    }),
    loadData() {
      this.loadOffers(this.filterData);
    },
    offersDetails(id) {
      this.$router.push({
        name: 'orders-offers-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    sendOffers() {
      this.sendOffersSelection({
        offers: this.offersSelection,
      }).then(() => {
        this.showSendOffers = false;
        this.$router.push({
          name: 'offers',
          params: { lang: this.$i18n.locale },
        });
      });
    },
    countOffersByStatus(status) {
      const array = this.getAllOffers.filter((val) => val.status === status);
      return array.length;
    },
    countOffersByStatusSent(status) {
      const array = this.getAllOffers.filter((val) => val.status !== status);
      return array.length;
    },
  },
};
</script>
